import React from 'react';
import PropTypes from 'prop-types';

/**
 * AU Kollapserbart Komponent
 *
 * Eksempel:
 * ```jsx
 *  <AUCollapsibleComponent
            key="collapse"
            collapsed
            header="Collapsible element"
            level={2}
            className="hide-for-small-only"
            onClick={(collapsed, node) => {
              this.setState({
                spinnerVisible: !collapsed,
              });
            }}
          >
            <h1>Test</h1>
          </AUCollapsibleComponent>
 * ```
 */

class AUCollapsibleComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: props.collapsed,
    };
  }

  componentDidMount() {
    const { attributes } = this.props;
    attributes.forEach((a) => {
      if (a.selector) {
        const el = this.node.querySelector(a.selector);
        if (el) {
          el.setAttribute(a.attr, a.value);
        }
      } else {
        this.node.setAttribute(a.attr, a.value);
      }
    });
  }

  render() {
    const {
      header,
      level,
      content,
      classNames,
      onClick,
      children,
      dataID,
      headerElement,
    } = this.props;
    const { collapsed } = this.state;
    let className = 'csc-frame au_collapsible';
    if (collapsed) {
      className += ' au_collapsed';
    }

    if (classNames) {
      className += ` ${classNames}`;
    }

    return (
      <div data-id={dataID} ref={(node) => { this.node = node; }} className={className}>
        {(() => {
          if (level > -1) {
            return (
              <div
                className="csc-header"
                onClick={() => {
                  this.setState((prevState) => ({
                    collapsed: !prevState.collapsed,
                  }), () => {
                    const { collapsed: newCollapsed } = this.state;
                    onClick(newCollapsed, this.node);
                  });
                }}
                onKeyUp={() => {

                }}
                role="button"
                tabIndex={0}
              >
                {(() => {
                  switch (level) {
                    case 0 && headerElement != null:
                      return headerElement;
                    case 1:
                      return (
                        <h1>
                          {header}
                        </h1>
                      );
                    case 2:
                      return (
                        <h2>
                          {header}
                        </h2>
                      );
                    case 3:
                      return (
                        <h3>
                          {header}
                        </h3>
                      );
                    case 4:
                      return (
                        <h4>
                          {header}
                        </h4>
                      );
                    case 5:
                      return (
                        <h5>
                          {header}
                        </h5>
                      );
                    default:
                      return (
                        <h6>
                          {header}
                        </h6>
                      );
                  }
                })()}
              </div>
            );
          }
          return null;
        })()}
        {children || content}
      </div>
    );
  }
}

AUCollapsibleComponent.defaultProps = {
  dataID: null,
  children: null,
  content: null,
  header: '',
  headerElement: null,
  level: 1,
  classNames: '',
  onClick: () => { },
  attributes: [],
};

AUCollapsibleComponent.propTypes = {
  dataID: PropTypes.string,
  children: PropTypes.element,
  collapsed: PropTypes.bool.isRequired,
  header: PropTypes.string,
  headerElement: PropTypes.element,
  level: PropTypes.number,
  content: PropTypes.element,
  classNames: PropTypes.string,
  onClick: PropTypes.func,
  attributes: PropTypes.arrayOf(PropTypes.shape({
    attr: PropTypes.string,
    value: PropTypes.string,
    selector: PropTypes.string,
  })),
};
AUCollapsibleComponent.displayName = 'AUCollapsibleComponent';
export default AUCollapsibleComponent;
